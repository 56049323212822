<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a
          style="font-size: 1vw;"
            href="https://www.eClock.com.mx"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            eClock.com.mx
          </a>
        </li>
      </ul>
      <div style="font-size: 1vw;" class="copyright text-wrap" @click="OnClick">
        &copy; {{ year }}, creado con
        <i class="tim-icons icon-heart-2"></i> para valorar e incentivar.
        {{ Datos }}
      </div>
    </div>
  </footer>
</template>
<script>
import BaseService from "@/api-services/base.service";
import Base from "@/api-services/base";
import Mantenimiento from "@/api-services/mantenimiento";
import swal from "sweetalert2";

export default {
  data() {
    return {
      Version: Base.Version,
      year: new Date().getFullYear(),
      Datos: "",

      cargando: false,
    };
  },
  computed: {
    idclient(){
      let perfil = JSON.parse(localStorage.getItem("perfilLocal"))
        let usuario = perfil.usuario.llaveProveedor
        let id = usuario.split("=")
        return id[1]
    },
  },
  mounted() {
    BaseService.CabiarTema();
  },
  methods: {
    OnClick() {
      if (this.cargando) return;
      Mantenimiento.Version(this, (response) => {
        swal.fire({
          title: "Versión",
          html: `Go: <b>${BaseService.Version}</b> <br>
          Api: <b>${response.data}</b> <br>
          Cliente: <b>${this.idclient}</b>`,
      //    text: `Go: ${BaseService.Version} - Api: ${response.data}`,
          //icon: 'success',
          iconHtml: '<i class="fas fa-seedling"></i>',
          showCancelButton: false,
          customClass: {
            confirmButton: "btn ",
          },
          confirmButtonText: "Ok",
          buttonsStyling: false,
        });
      });
      /*
        if (!BaseService.IsProd())s
          this.Datos = `${localStorage.suscripcionID}/${localStorage.sesion}`*/
    },
  },
};
</script>
<style></style>
